<script setup lang="ts">
import { marcas, type Marca } from '@/scripts/marcas.ts';
import authStore from '@/store/authStore.ts';

const store = authStore();

// Ajustar imagens de acordo com a marca
const url = new URL(window.location.href);
const marcaHost = url.searchParams.get('referrer') || url.hostname;
let marca: Marca | undefined;

if (marcaHost) {
  marca = marcas.find((m) => m.asset && marcaHost.includes(m.asset));
  marca ||= marcas.find((m) => m.asset && 'anhanguera');
  if (marca) store.setMarca(marca);
} else if (store.marca) {
  marca = store.marca;
}

const imageUrl = marca ? `/logos/${marca.asset}.png` : '/logos/anhanguera.png';
</script>

<template>
  <div class="container-fluid">
    <div class="row min-vh-100">
      <div class="col-lg-5 bg-white d-flex flex-column p-4 p-md-5">
        <img :src="imageUrl" class="me-auto" :alt="marca?.asset" height="60" data-testid="logo">
        <div class="my-5">
          <h2>{{ $route.meta.title }}</h2>
          <p>{{ $route.meta.subtitle }}</p>
        </div>
        <router-view />
      </div>
      <div class="col-lg-7 d-none d-lg-block position-relative p-0">
        <div class="login-home-img position-sticky top-0 end-0 bottom-0 start-50" />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.login-home-img {
  background-image: url("/img/bg.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
</style>
