<script setup lang="ts">
import { ref, type PropType } from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { type Marca } from '@/scripts/marcas.ts';
import authStore from '@/store/authStore.ts';

const store = authStore();

const props = defineProps({
  marca: {
    type: Object as PropType<Marca>,
    required: false,
    default: null,
  },
});

const currentMarca = ref<Marca>();

if (props.marca) {
  currentMarca.value = props.marca;
} else if (store.marca) {
  currentMarca.value = store.marca;
}
</script>

<template>
  <div class="social position-fixed bottom-0 start-0 end-0 d-flex justify-content-center my-3" v-if="currentMarca">
    <a
      class="social-item p-3 me-3 shadow"
      :href="sanitizeUrl(currentMarca.midiasSociais.facebook)"
      target="_blank"
      rel="noopener"
      v-if="currentMarca.midiasSociais.facebook"
      title="Facebook">
      <img src="/graph/social/fb.svg" alt="Facebook">
    </a>
    <a
      class="social-item p-3 me-3 shadow"
      :href="sanitizeUrl(currentMarca.midiasSociais.twitter)"
      target="_blank"
      rel="noopener"
      v-if="currentMarca.midiasSociais.twitter"
      title="X (Twitter)">
      <img src="/graph/social/x.svg" alt="X (Twitter)">
    </a>
    <a
      class="social-item p-3 me-3 shadow"
      :href="sanitizeUrl(currentMarca.midiasSociais.youtube)"
      target="_blank"
      rel="noopener"
      v-if="currentMarca.midiasSociais.youtube"
      title="Youtube">
      <img src="/graph/social/yt.svg" alt="Youtube">
    </a>
    <a
      class="social-item p-3 me-3 shadow"
      :href="sanitizeUrl(currentMarca.midiasSociais.instagram)"
      target="_blank"
      rel="noopener"
      v-if="currentMarca.midiasSociais.instagram"
      title="Instagram">
      <img src="/graph/social/ig.svg" alt="Instagram">
    </a>
  </div>
</template>

<style scoped>
.social {
  z-index: 1;
}
.social-item {
  border-radius: 100%;
}
.social-item > img {
  height: 1.2rem;
}

/* Media query para telas menores que 320x480 pixels */
@media screen and (max-width: 320px) and (max-height: 480px) {
  .social {
    position: relative !important;
  }
}

/* Media query para telas maiores que 320x480 pixels */
@media screen and (min-width: 321px) and (min-height: 481px) {
  .social {
    position: fixed !important;
  }
}
</style>
