import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type NavigationGuardNext,
} from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import LayoutV2 from '@/views/v2/LayoutV2.vue';
import authStore from '@/store/authStore.ts';
import { changeIcon } from '@/scripts/FaviconHelper.ts';
import AuthService from '@/services/AuthService.ts';

let authService: AuthService;

declare global {
  interface Window {
    gaV4?: Function;
  }
}

const routes = [
  {
    path: '/v2',
    component: LayoutV2,
    children: [
      {
        path: '',
        component: () => import('@/views/v2/LoginView.vue'),
        name: 'v2-login',
        meta: {
          title: 'Boas vindas',
          subtitle: 'Que bom ver você! Acesse o Portal do Aluno aqui.',
        },
        beforeEnter: () => {
          // Migração v1 -> v2
          if (!window.localStorage.getItem('useV2')) {
            window.localStorage.setItem('useV2', 'true');

            // Avisar Google
            if (window.gaV4) {
              window.gaV4('event', 'experiencia', {
                version: 2,
              });
            }
          }
        },
      },
      {
        path: 'cursos',
        component: () => import('@/views/v2/CursosView.vue'),
        name: 'v2-cursos',
        meta: {
          title: 'Acesse seu curso',
          subtitle: 'Escolha seu curso e continue sua jornada.',
        },
      },
      {
        path: 'recover',
        component: () => import('@/views/AlterarSenhaView.vue'),
        name: 'v2-recover',
        meta: {
          title: 'Recuperação de acesso',
          subtitle: 'Crie ou altere sua senha.',
        },
        props: {
          newLayout: true,
        },
      },
    ],
    beforeEnter: async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const store = authStore();

      // As rotas não precisam esperar o token CSRF para serem acessadas
      next();

      if (!store.tokenCsrf) {
        if (!authService) authService = new AuthService();

        const result = await authService.getTokenCsrf();

        if (result.ok && result.data?.csrfToken) {
          store.setTokenCsrf(result.data.csrfToken);
        }
      }
    },
  },
  {
    path: '/',
    name: 'home',
    alias: '/AccountAluno/Login',
    component: LoginView,
    meta: {
      title: 'Acessar PDA',
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      const useV2 = window.localStorage.getItem('useV2');

      if (useV2 === 'true') {
        return { name: 'v2-login', query: from.query };
      }

      return true;
    },
  },
  {
    path: '/meuscursos',
    name: 'meuscursos',
    component: () => import('@/views/MeusCursosView.vue'),
    meta: {
      title: 'Meus cursos',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/LogoutView.vue'),
    meta: {
      title: 'Até logo!',
    },
  },
  {
    path: '/alterarSenha',
    name: 'alterarsenha',
    alias: '/AccountAluno/AccountResetPassword',
    component: () => import('@/views/AlterarSenhaView.vue'),
    meta: {
      title: 'Alterar senha',
    },
  },
  {
    path: '/recuperar/:token',
    name: 'recuperar',
    component: () => import('@/views/RecuperarSenhaView.vue'),
    meta: {
      title: 'Alterar Senha',
    },
  },
  {
    path: '/atualizacao-cadastral',
    name: 'atualizacaocadastral',
    meta: {
      title: 'Atualização cadastral',
    },
    component: () => import('@/views/AtualizacaoCadastralView.vue'),
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  const store = authStore();

  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }

  if (store.marca) {
    changeIcon(store.marca);
  }
});

export default router;
export { router, routes };
