import { type AuthModel, type AutenticacaoModel } from '@/models/AuthModel.ts';
import BaseService from '@/services/BaseService.ts';
import { type BaseResponse } from '@/models/base/BaseResponseModel.ts';
import type { TokenCsrfModel } from '@/models/TokenCsrfModel';

export default class AuthService extends BaseService {
  private endpointAuth: string;

  constructor() {
    super();
    this.endpointAuth = import.meta.env.VITE_ENDPOINT_AUTH;
  }

  public async login(
    login: string,
    senha: string,
    clientId: string | null,
    redirectUri?: string | null,
    state?: string | null,
  ): Promise<BaseResponse<AuthModel>> {
    const endpointUrl = new URLSearchParams();

    if (clientId) endpointUrl.append('client_id', clientId);
    if (redirectUri) endpointUrl.append('redirect_uri', redirectUri);
    if (state) endpointUrl.append('state', state);

    return this.post(`${this.endpointAuth}?${endpointUrl.toString()}`, null, { login, senha });
  }

  public async validarCPF(cpf: string): Promise<BaseResponse<AutenticacaoModel>> {
    return this.get('api/v2/Autenticacao', { 'X-PII-Cpf': cpf });
  }

  public async getTokenCsrf(): Promise<BaseResponse<TokenCsrfModel>> {
    return this.get('antiforgery/token', {});
  }
}
