<script setup lang="ts">
import { ref } from 'vue';

const showPrivacyBar = ref(!window.localStorage.getItem('alert:privacy-bar'));

function hidePrivacyBar() {
  showPrivacyBar.value = false;
  window.localStorage.setItem('alert:privacy-bar', 'false');
}
</script>

<template>
  <div class="position-fixed bottom-0 start-0 end-0 d-flex align-items-center" style="z-index: 10;">
    <div
      class="privacy-bar d-flex align-items-center text-white p-3 m-3 rounded-3 shadow ms-auto me-auto"
      id="privacyBar"
      v-if="showPrivacyBar">
      <p class="mb-0">
        Esse site utiliza cookies para oferecer uma melhor experiência de navegação, analisar como
        você interage em nosso site, otimizar o desempenho e personalizar o conteúdo. Ao utilizar
        este site, você concorda com o uso de cookies. Saiba mais em nosso
        <a href="https://www.canaldatransparencia.com.br/cogna" target="_blank" rel="noopener">
          Portal de Privacidade
        </a>.
      </p>
      <button id="agreeButton" type="button" @click="hidePrivacyBar()" class="btn btn-light">
        Ok
      </button>
    </div>
  </div>
</template>

<style scoped>
.privacy-bar {
  background: rgba(0, 0, 0, .5);
  backdrop-filter: blur(10px);
  width: calc(100% - 20px);
  max-width: 800px;
}
</style>
