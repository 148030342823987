import './styles/bootstrap.scss';
import './styles/styles.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router/router.ts';

const app = createApp(App)
  .use(createPinia())
  .use(router);

app.mount('#app');
