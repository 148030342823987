import BaseService from '@/services/BaseService.ts';
import { type BaseResponse } from '@/models/base/BaseResponseModel.ts';
import { type ClientModel } from '@/models/ClientModel.ts';

export default class ClientService extends BaseService {
  private endpointClient: string;

  constructor() {
    super();
    this.endpointClient = import.meta.env.VITE_ENDPOINT_CLIENT;
  }

  public async getClient(clientId: string): Promise<BaseResponse<ClientModel>> {
    return this.get(`${this.endpointClient}/${clientId}`, null);
  }
}
