<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import PrivacyBar from '@/components/PrivacyBar.vue';
import RedesSociaisMarca from '@/components/RedesSociaisMarca.vue';
import { marcas, type Marca } from '@/scripts/marcas.ts';
import AuthService from '@/services/AuthService.ts';
import ClientService from '@/services/ClientService.ts';
import authStore from '@/store/authStore.ts';

const authService = new AuthService();
const router = useRouter();
const store = authStore();

const style = ref('style');
const autenticado = ref(false);
const submetido = ref(false);
const nome = ref('');
const erro = ref('');
const login = ref('');
const senha = ref('');
const loading = ref(false);
const mensagem = ref('');

// Ajustar imagens de acordo com a marca
const url = new URL(window.location.href);
const marcaHost = url.searchParams.get('referrer') || url.hostname;
let marca: Marca | undefined;

if (marcaHost) {
  marca = marcas.find((m) => m.asset && marcaHost.includes(m.asset));
  marca ||= marcas.find((m) => m.asset && 'anhanguera');
  if (marca) store.setMarca(marca);
} else if (store.marca) {
  marca = store.marca;
}

const imageUrl = marca ? `/img/${marca.asset}/logopa.png` : '/img/kroton/logopa.png';
const imageBody = marca ? `/img/${marca.asset}/login-pa.jpg` : '/img/kroton/login-pa.jpg';

// Mensagens vindas da URL
if (url.searchParams.get('m')) {
  mensagem.value = url.searchParams.get('m') as string;
}

// Adiantar o carregamento do client (em um processo separado)
if (url.searchParams.has('client_id')) {
  setTimeout(async () => {
    const service = new ClientService();
    const client = await service.getClient(url.searchParams.get('client_id') as string);

    if (client?.ok && client?.data) store.setClient(client.data);
  }, 10);
}

async function handleLogin() {
  try {
    erro.value = '';
    loading.value = true;

    const currentUrl = new URL(window.location.href);
    const clientId = currentUrl.searchParams.get('client_id');
    const redirectUri = currentUrl.searchParams.get('redirect_uri');
    const state = currentUrl.searchParams.get('state');

    const result = await authService.login(login.value, senha.value, clientId, redirectUri, state);

    if (result.ok && result.data) {
      if (result.data.autenticado) {
        nome.value = result.data.nome;

        store.setAlunoId(result.data.alunoId);
        store.setName(result.data.nome);
        store.clearMessage();

        router.push({
          name: 'meuscursos',
        });
      } else {
        erro.value = 'Usuário ou senha inválidos. Insira os dados corretos e tente novamente.';
        loading.value = false;
      }
    } else {
      switch (result.response?.status) {
        case 400:
        case 401: {
          erro.value = 'Usuário ou senha inválidos. Insira os dados corretos e tente novamente.';
          break;
        }

        case 429: {
          erro.value = 'Você está tentando muitas vezes. Aguarde alguns minutos antes de continuar.';
          break;
        }

        case 404:
        case 500:
        case 502: {
          erro.value = 'Estamos com algum problema. Tente novamente mais tarde por favor.';
          break;
        }

        default: {
          erro.value = `[${result.response?.status}] Ocorreu um erro ao efetuar seu acesso. Tente novamente.`;
          break;
        }
      }

      loading.value = false;
    }

    autenticado.value = result.ok as boolean;
    mensagem.value = '';
    submetido.value = true;
    loading.value = false;
  } catch (error) {
    submetido.value = true;
    autenticado.value = false;
    loading.value = false;

    if (!window.navigator.onLine) {
      erro.value = 'Você está conectado? Por favor, cheque sua conexão com a internet e tente novamente.';
      return;
    }

    erro.value = 'Ocorreu um erro ao efetuar seu acesso. Tente novamente.';
  }
}
</script>

<template>
  <component :is="style">
    body{
    background-image: url({{ imageBody }});
    }
  </component>
  <div class="container-fluid">
    <div class="alert alert-warning d-flex align-items-center mt-2">
      <div>
        <p class="mb-1"><strong>Novidade</strong></p>
        <p class="m-0">Olá aluno! Em breve essa página será atualizada com um novo visual.</p>
      </div>
      <RouterLink :to="{ name: 'v2-login', query: $route.query }" class="btn btn-primary ms-auto" data-testeid="experience-v2">
        Experimente agora
      </RouterLink>
    </div>

    <div class="row">
      <div class="col-sm-2 col-md-5 col-lg-6 col-xl-7" />
      <div class="col-sm-10 col-md-7 col-lg-6 col-xl-5">
        <div class="card p-4 mt-3 m-md-5">
          <img :src="imageUrl" class="img-fluid" :alt="marca?.asset">
          <hr>

          <form accept-charset="utf-8" @submit.prevent="handleLogin">
            <h4 class="mb-3">Bem-vindo aluno!</h4>
            <div
              id="feedbackAlert"
              v-if="submetido || mensagem"
              class="alert"
              :class="{
                'alert-success': submetido && autenticado,
                'alert-danger': submetido && !autenticado,
                'alert-warning': !submetido && mensagem,
              }">
              {{ erro || mensagem || `Bem vindo ${nome}` }}
            </div>

            <div class="mb-3 form-floating">
              <input
                type="text"
                id="username"
                class="form-control"
                placeholder="CPF"
                maxlength="150"
                autocomplete="username"
                v-model="login"
                required
                :disabled="loading">
              <label for="username">CPF</label>
            </div>
            <div class="mb-3 form-floating">
              <input
                type="password"
                id="password"
                class="form-control"
                placeholder="Senha"
                autocomplete="current-password"
                v-model="senha"
                required
                :disabled="loading"
                maxlength="128">
              <label for="password">Senha</label>
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" id="checkbox" class="form-check-input" :disabled="loading" />
              <label class="form-check-label" for="checkbox">&nbsp;Salvar dados</label>
            </div>
            <p><a aria-label="alterar-senha" href="/alterarSenha">Esqueci minha senha / Primeiro acesso</a></p>
            <button type="submit" class="btn btn-primary" :disabled="loading">
              Entrar <span v-if="loading" class="spinner-border spinner-border-sm ms-1" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <PrivacyBar />
  <RedesSociaisMarca :marca="marca" />
</template>

<style>
body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>

<style scoped>
.card {
  border-radius: 1.4rem; /* por conta da v2 */
}
</style>
